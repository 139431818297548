.time-picker-container {
    position: relative;
    display: inline-block;
    width: 120px;
  }
  
  .time-input {
    width: 100%;
    padding: 5px;
    font-size: 16px;
  }
  
  .time-picker {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 10;
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .time-picker-controls {
    display: flex;
    justify-content: space-between;
  }
  
  .time-picker-hour, .time-picker-minute {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .time-picker-hour button, .time-picker-minute button {
    background: none;
    border: none;
    font-size: 18px;
    color: #4767a0;
    cursor: pointer;
  }
  
  .time-picker-hour span, .time-picker-minute span {
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
  }
  
  .time-grid {
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(6, 1fr); */
    gap: 5px;
    margin-top: 10px;
  }
  
  .time-grid-item {
    padding: 10px;
    text-align: center;
    background-color: #f0f0f0;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .time-grid-item:hover {
    background-color: #e0e0e0;
  }
  