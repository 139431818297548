.multi-page-link {
}
.multi-page-link a {
  padding-inline: 20px;
  padding-block: 5px;
  font-size: 14px;
  color: #141414;
  font-weight: 600;
}
.multi-page-link .active {
  /* background: #003070; */
  background: rgba(0, 48, 112);
  color: white;
  border-radius: 5px;
}

.tab-container {
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  gap: 16px;
  border-bottom: 2px solid #ddd; /* Divider between tabs and content */
}

.tab-item {
  position: relative;
  padding-bottom: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #555;
  transition: color 0.3s ease, transform 0.3s ease;
}

.active-tab {
  color: #007bff;
  font-weight: 600;
}

.active-tab::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 3px;
  background-color: #007bff;
  border-radius: 2px;
  transition: width 0.3s ease;
  width: 100%;
}
