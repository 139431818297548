
//
// toastr.scss
//


/* =============
   Notification
============= */
#toast-container {
    z-index: 1000000;
    position: fixed;
    top: 95px;
    right: 10px;

    > div {
        padding: 15px;
        margin-bottom: 0;
        box-shadow: $box-shadow;
        opacity: 1;
        cursor: pointer;
        &:hover {
            box-shadow: $box-shadow;
            opacity: 0.9;

        }
    }

    &.toast-top-full-width, &.toast-bottom-full-width{
        > div{
          min-width: 96%;
          margin: 4px auto;
        }
      }

}


@each $color, $value in $theme-colors {
    .toast-#{$color} {
        border: 2px solid $value !important;
        background-color: rgba(($value), 0.8) !important;
    }
}


// for error

.toast-error {
    background-color: rgba($danger,0.8);
    border: 2px solid $danger;
}

.toastr-options{
    padding: 24px;
    background-color: lighten($gray-200, 2%);
    margin-bottom: 0;
    border: 1px solid $border-color;
}


#toast-container > div {
    position: relative; /* Ensure positioning context for the close icon */
    padding: 10px; /* Adjust padding as needed */
}

#toast-container > div::after {
    content: "✖"; /* Unicode character for the close icon */
    position: absolute;
    top: 5px; /* Adjust as necessary */
    right: 10px; /* Adjust as necessary */
    cursor: pointer; /* Change cursor to pointer */
    font-size: 16px; /* Adjust size as needed */
    color: #333; /* Change color as needed */
}

#toast-container > div:hover::after {
    color: red; /* Change color on hover to indicate it's clickable */
}